import React, { useEffect } from "react";
import "./App.css";

import {
  Box,
  Card,
  Loader,
  Page,
  WixDesignSystemProvider,
  Text,
  Image,
  Button,
  Badge,
  Layout,
  Cell,
  BadgeSkin,
  FormField,
  Input,
  MarketingPageLayoutContent,
  TextButton,
  MarketingPageLayout,
  PageSection,
  MarketingLayout,
  Timeline,
  SectionHelper,
  InputArea,
  CopyClipboard,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";
import Flag from "./cite-flag.png";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation();
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [citationStatus, setCitationStatus] = React.useState(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [siteUrl, setSiteUrl] = React.useState("");

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    setStatus();
    // if status is null/undefined, check again in every 5 seconds
    const checker = setInterval(() => {
      if (!citationStatus) {
        setStatus();
      } else {
        clearInterval(checker);
      }
    }, 15000);
    const url = new URLSearchParams(window.location.search).get("siteUrl");
    if (url) {
      setSiteUrl(url);
    }
    return () => {
      clearInterval(checker);
    };
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/citations/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          setEmail(data.email);
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(!data?.instance?.isFree);
      })
      .catch(() => {});
  }

  function setStatus() {
    fetch(BASE_URL + "/status", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCitationStatus(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=28af51f1-3e60-4805-8b98-a9052ebfa7b0&redirectUrl=https://certifiedcode.wixsite.com/citations/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t("loading")} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const planId = instanceData?.instance?.billing?.packageName;

  const statuses: {
    [key: string]: {
      title: string;
      subtitle: string;
      skin: string;
    };
  } = {
    PENDING: {
      title: t("pending-title"),
      subtitle: t("pending-subtitle"),
      skin: "standard",
    },
    IN_PROGRESS: {
      title: t("progress-title"),
      subtitle: t("progress-subtitle"),
      skin: "warning",
    },
    ACTION_REQUIRED: {
      title: t("action-title"),
      subtitle: t("action-subtitle"),
      skin: "danger",
    },
    DONE: {
      title: t("done-title"),
      subtitle: t("done-subtitle"),
      skin: "success",
    },
  };
  // const businessName =
  //   citationStatus?.fields?.["Business Name"] || "your business";
  const expirationDate = new Date(
    instanceData?.instance?.billing?.expirationDate
  ).toLocaleDateString();
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      {isLoading && (
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t("loading")} />
            </Box>
          </Page.Content>
        </Page>
      )}
      {!citationStatus ? (
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          verticalSize="large"
          content={
            <Box height="100vh" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t("citations-title")}
                subtitle={t("citations-content")}
                actions={
                  <Box gap="SP2">
                    <Button
                      as="a"
                      href={`#tally-open=wL686p&instanceId=${
                        instanceData?.instance?.instanceId
                      }&tally-layout=modal&tally-width=${
                        // based on 90% of the screen width
                        window.innerWidth * 0.9
                      }&tally-auto-close=0`}
                      target="_blank"
                      skin="ai"
                      suffixIcon={<Icons.AIFilled />}
                      size="large"
                    >
                      {t("citations-get-started-button")}
                    </Button>
                    <Button
                      suffixIcon={<Icons.PremiumFilled />}
                      skin="premium"
                      priority="secondary"
                      size="large"
                      as="a"
                      href={`https://www.wix.com/apps/upgrade/${"28af51f1-3e60-4805-8b98-a9052ebfa7b0"}?appInstanceId=${
                        instanceData?.instance?.instanceId
                      }`}
                      target="_blank"
                    >
                      {t("citations-view-pricing-button")}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={Flag} />}
        />
      ) : (
        <Page height="100vh">
          <Page.Header
            title={t("citations-title")}
            subtitle={t("citations-content")}
            actionsBar={
              <Button
                skin="dark"
                onClick={() => {
                  window.Intercom("showNewMessage");
                }}
              >
                {t("citations-contact-support-button")}
              </Button>
            }
          />

          <Page.Content>
            <Layout>
              <Cell span={8}>
                <Card>
                  <Card.Header
                    title={t("listing_status", { businessName: "your business" })}
                    suffix={
                      citationStatus?.status === "PENDING" && !isUpgraded ? (
                        <Button
                          prefixIcon={<Icons.PremiumFilled />}
                          skin="premium"
                          as="a"
                          href={`https://www.wix.com/apps/upgrade/${"28af51f1-3e60-4805-8b98-a9052ebfa7b0"}?appInstanceId=${
                            (instanceData as any)["instance"]["instanceId"]
                          }`}
                          target="_blank"
                        >
                          {t("get-listed-button")}
                        </Button>
                      ) : (
                        <Badge
                          skin={
                            statuses[citationStatus?.status || "PENDING"]?.skin
                          }
                        >
                          {statuses[citationStatus?.status || "PENDING"]?.title}
                        </Badge>
                      )
                    }
                    subtitle={
                      citationStatus?.status === "PENDING" && !isUpgraded
                        ? t("upgrade-citations")
                        : statuses[citationStatus?.status || "PENDING"]
                            ?.subtitle
                    }
                  />
                  {citationStatus?.status === "DONE" && (
                    <Card.Content>
                      <Box gap="SP1" direction="vertical">
                        {/* {Object.keys(citationStatus?.fields || {}).map(
                          (key, i) => {
                            return (
                              <FormField label={key}>
                                <Input
                                  disabled
                                  value={citationStatus?.fields[key]}
                                ></Input>
                              </FormField>
                            );
                          }
                        )} */}
                        {Object.keys(citationStatus.documents || []).map(
                          (key, i) => {
                            return (
                              <Button
                                as="a"
                                href={`https://bec40df1-03f3-4ae0-bdb8-3da177c3f9b7.usrfiles.com/ugd/${
                                  citationStatus.documents[key].split("/")[4]
                                }`}
                                target="_blank"
                              >
                                {t("download-list-button")}
                              </Button>
                            );
                          }
                        )}
                      </Box>
                      {citationStatus?.links && (
                        <FormField
                          label="Guest Blogs (backlinks) included"
                          suffix={
                            <CopyClipboard
                              value={citationStatus?.links || ""}
                              resetTimeout={3000}
                            >
                              {({ isCopied, copyToClipboard, reset }: any) => (
                                <Box align="space-between">
                                  <TextButton
                                    onClick={() =>
                                      isCopied ? reset() : copyToClipboard()
                                    }
                                  >
                                    {!isCopied ? "Copy" : "Copied!"}
                                  </TextButton>
                                </Box>
                              )}
                            </CopyClipboard>
                          }
                        >
                          <InputArea
                            value={citationStatus?.links || ""}
                            readOnly
                          />
                        </FormField>
                      )}
                    </Card.Content>
                  )}
                </Card>
              </Cell>
              <Cell span={4}>
                <Card>
                  <Card.Header title={t("plan-card-title")} />
                  <Card.Divider />
                  <Card.Content>
                    <Box gap="SP2" direction="vertical">
                      {instanceData?.instance?.billing?.autoRenewing ===
                        false && (
                        <SectionHelper
                          title={t("delisting_message", {
                            delistingDate: expirationDate,
                          })}
                          actionText={t("plan-action-text")}
                          onAction={() => {
                            window.open(
                              `https://premium.wix.com/wix/api/mpContainerStaticController?originWidgetName=billings`,
                              "_blank"
                            );
                          }}
                          appearance="danger"
                        >
                          {t("plan-section-content")}
                        </SectionHelper>
                      )}
                      <Timeline
                        items={[
                          {
                            label: t("label-1-content"),
                            customPrefix: planId ? (
                              <Icons.StatusCompleteFilledSmall />
                            ) : (
                              <Icons.PremiumFilledSmall />
                            ),
                          },
                          {
                            label: t("label-2-content"),
                            customPrefix: [
                              "citations-plus",
                              "citations-pro",
                              "citations-elite",
                            ].includes(planId) ? (
                              <Icons.StatusCompleteFilledSmall />
                            ) : (
                              <Icons.PremiumFilledSmall />
                            ),
                            suffix: planId === "citations" && (
                              <Button
                                skin="premium"
                                size="tiny"
                                prefixIcon={<Icons.PremiumFilled />}
                                as="a"
                                href={`https://www.wix.com/apps/upgrade/${"28af51f1-3e60-4805-8b98-a9052ebfa7b0"}?appInstanceId=${
                                  instanceData?.instance?.instanceId
                                }`}
                                target={"_blank"}
                              >
                                Upgrade
                              </Button>
                            ),
                          },
                          {
                            label: t("label-3-content"),
                            customPrefix: [
                              "citations-pro",
                              "citations-elite",
                            ].includes(planId) ? (
                              <Icons.StatusCompleteFilledSmall />
                            ) : (
                              <Icons.PremiumFilledSmall />
                            ),
                            suffix: planId === "citations-plus" && (
                              <Button
                                skin="premium"
                                size="tiny"
                                prefixIcon={<Icons.PremiumFilled />}
                                as="a"
                                href={`https://www.wix.com/apps/upgrade/${"28af51f1-3e60-4805-8b98-a9052ebfa7b0"}?appInstanceId=${
                                  instanceData?.instance?.instanceId
                                }`}
                                target={"_blank"}
                              >
                                {t("upgrade-button")}
                              </Button>
                            ),
                          },
                          {
                            label: t("label-4-content"),
                            customPrefix: ["citations-elite"].includes(
                              planId
                            ) ? (
                              <Icons.StatusCompleteFilledSmall />
                            ) : (
                              <Icons.PremiumFilledSmall />
                            ),
                            suffix: planId === "citations-pro" && (
                              <Button
                                skin="premium"
                                size="tiny"
                                prefixIcon={<Icons.PremiumFilled />}
                                as="a"
                                href={`https://www.wix.com/apps/upgrade/${"28af51f1-3e60-4805-8b98-a9052ebfa7b0"}?appInstanceId=${
                                  instanceData?.instance?.instanceId
                                }`}
                                target={"_blank"}
                              >
                                {t("upgrade-button")}
                              </Button>
                            ),
                          },
                        ]}
                      />
                    </Box>
                  </Card.Content>
                </Card>
              </Cell>
              <Cell>
                <PageSection title={t("services-title")} showDivider />
              </Cell>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    badge={
                      <Badge skin="success">{t("services-1-badge")}</Badge>
                    }
                    title={t("services-1-title")}
                    description={t("services-1-description")}
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<Icons.ExternalLinkSmall />}
                          as="a"
                          href="https://www.certifiedcode.us/pricing/seo?utm_source=seo-with-ai-banner"
                          target="_blank"
                        >
                          {t("services-1-learn-button")}
                        </Button>
                      </Box>
                    }
                    // imageBackgroundColor="B40"
                    image={<></>}
                  />
                </Card>
              </Cell>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    badge={
                      <Badge skin="warning">{t("services-2-badge")}</Badge>
                    }
                    size="tiny"
                    title={t("services-2-title")}
                    description={t("services-2-description")}
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          prefixIcon={<Icons.Add />}
                          as="a"
                          href="https://go.certifiedcode.global/get/a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c"
                          target="_blank"
                        >
                          {t("services-2-add-to-site-button")}
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
              <Cell>
                <Card>
                  <MarketingLayout
                    size="tiny"
                    title={t("services-3-title")}
                    description={t("services-3-description")}
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          prefixIcon={<Icons.Add />}
                          as="a"
                          href="https://go.certifiedcode.global/get/8d864cca-7715-415d-8fa9-55206d15ae6d"
                          target="_blank"
                        >
                          {t("services-3-add-to-site-button")}
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
            </Layout>
          </Page.Content>
        </Page>
      )}
    </WixDesignSystemProvider>
  );
}

export default App;
